import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput, TextInput, SwitchInput } from '../inputs';
import {
  useDispatch,
  addResource,
  getWarehouseById,
  useSelector,
  systemSelectors,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString, formatContactApi } from '../../../lib';
import { SelectOption, WarehouseDetailsAPI } from '../../../types';
import { Grid2 as Grid } from '@mui/material';
import { ContactAddressFields } from './ContactFields';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId?: string | number;
}

interface State
  extends Omit<
    WarehouseDetailsAPI,
    'externalID' | 'shipApiID' | 'address' | 'contact'
  > {
  address1: string;
  city?: string;
  zip: string;
  stateID: number | '';
  stateCode?: string;
  countryID?: number | '';
  contactId?: number;
  createInShipEngine?: boolean;
}

const initialState: State = {
  name: '',
  phone: '',
  address1: '',
  city: '',
  stateID: '',
  zip: '',
  countryID: '',
  timeZoneID: '',
  createInShipEngine: true,
};

export function WarehouseForm({ open, handleClose, warehouseId }: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const countryUsId = useSelector(systemSelectors.countryUsId);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    ...initialState,
    countryID: countryUsId,
  });

  useEffect(() => {
    (async function () {
      if (warehouseId) {
        const { data } = await dispatch(getWarehouseById(warehouseId));
        if (data) {
          const { address, contact, ...rest } = data;
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(rest),
            contactId: contact?.id,
            address1: address?.address1 ?? '',
            city: address?.city ?? '',
            stateID: address?.stateID ?? '',
            zip: address?.zip ?? '',
            countryID: address?.countryID ?? countryUsId,
            createInShipEngine: !!data.shipApiID,
          }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = generatePayload(values);
      const { error } = await dispatch(
        addResource({
          baseUrl: '/warehouses',
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit warehouse details</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 520 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='name'
            formikProps={formik}
            label='Warehouse name'
            className={classes.input}
          />
        </Grid>
        <ContactAddressFields
          formikProps={formik}
          addressFieldName='address1'
          addressFieldFullWidth
          idUpperCase
          inputClassName={classes.input}
        />
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='phone'
            formikProps={formik}
            label='Phone'
            className={classes.input}
            format='phone'
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            name='timeZoneID'
            formikProps={formik}
            label='Time zone'
            className={classes.input}
            options={timeZoneOptions}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SwitchInput
            name='createInShipEngine'
            formikProps={formik}
            label='Create in ShipEngine'
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { WarehouseForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));

function generatePayload(state: State) {
  const { address1, city, stateID, zip, countryID, ...rest } = state;
  return {
    ...rest,
    contact: formatContactApi({
      id: state.contactId,
      lastName: state.name,
      address1,
      city,
      stateId: stateID,
      zip,
      countryId: countryID,
      phone: state.phone,
    }),
  };
}

const timeZoneOptions: Array<SelectOption> = [
  { name: 'Pacific Time Zone', id: 'America/Los_Angeles' },
  { name: 'Mountain Time Zone', id: 'America/Denver' },
  { name: 'Central Time Zone', id: 'America/Chicago' },
  { name: 'Eastern Time Zone', id: 'America/New_York' },
];
