import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, addResource, deleteResource } from '../../../state';
import { useRefresh } from '../../../hooks';
import { CustomerWarehouseFeeAPI } from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => any;
  customerWarehouseFee: CustomerWarehouseFeeAPI;
  customerId: number;
}

interface State {
  amount: number | '';
}

const initialState: State = {
  amount: '',
};

export function CustomerWarehouseFeeForm({
  open,
  handleClose,
  customerWarehouseFee,
  customerId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<State>(initialState);

  const customerWarehouseFeeId = customerWarehouseFee.customerWarehouseFeeID;

  useEffect(() => {
    setState((cur) => ({
      ...cur,
      amount: customerWarehouseFee.amount || '',
    }));
  }, [customerWarehouseFee.amount]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload: CustomerWarehouseFeeAPI = {
        ...customerWarehouseFee,
        ...values,
        customerWarehouseFeeID: customerWarehouseFeeId,
      };
      const { error } = await dispatch(
        addResource<CustomerWarehouseFeeAPI, CustomerWarehouseFeeAPI>({
          baseUrl: `/customers/${customerId}/warehouse/${customerWarehouseFee.warehouseID}/warehouse-fee`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [
      customerWarehouseFeeId,
      dispatch,
      handleClose,
      refresh,
      customerId,
      customerWarehouseFee,
    ],
  );

  const handleDelete = useCallback(async () => {
    if (customerWarehouseFeeId) {
      setSubmitting(true);
      const response = await dispatch(
        deleteResource({
          baseUrl: `/customers/${customerId}/warehouse/${customerWarehouseFee.warehouseID}/warehouse-fees`,
          id: customerWarehouseFeeId,
        }),
      );
      setSubmitting(false);
      const { error } = response;
      if (!error) {
        refresh();
        handleClose();
      }
    }
  }, [
    customerWarehouseFeeId,
    dispatch,
    customerId,
    customerWarehouseFee.warehouseID,
    refresh,
    handleClose,
  ]);

  const formik = useFormik<State>({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          customerWarehouseFeeId ? 'Edit' : 'Add'
        } customer warehouse fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      submitting={submitting}
      isEdit={!!customerWarehouseFeeId}
      handleDelete={handleDelete}
    >
      <TextInput
        name='amount'
        formikProps={formik}
        label='Amount'
        className={classes.input}
        required
        format='currency'
      />
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { CustomerWarehouseFeeForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: '100%',
    },
  }),
);

const schema = object().shape({
  amount: string().required('Required'),
});
