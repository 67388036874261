import { Fragment, useCallback, useEffect, useState } from 'react';
import { Chip, Grid2 as Grid, Tooltip } from '@mui/material';
import { makeStyles, TextButton } from '../../../themes';
import { FormikProps, useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import {
  AutocompleteInput,
  CheckboxInput,
  ChipsInput,
  SelectInput,
  TextInput,
} from '../inputs';
import {
  useDispatch,
  transferInventoryItem,
  getBasicWarehouses,
  useSelector,
  warehousesSelectors,
  getInventoryItemDetails,
  assignTransferInventoryItem,
} from '../../../state';
import { useGetUsersForSelect, useIsMobile, useRefresh } from '../../../hooks';
import {
  AddPhysicalItemsToWarehouseLocationRequest,
  InventoryTransferJobRequest,
  TransferInventoryRequest,
  WarehouseLocationInventoryItemAPI,
} from '../../../types';
import { MobileModalForm } from '../../../themes/mobile/MobileModalForm';
import { InventoryPhysicalItems } from '../../../pages/inventory/InventoryPhysicalItems';
import { InfoIcon } from '../../../assets';

interface TransferInventoryItemProps {
  open: boolean;
  handleClose: () => any;
  currentWarehouseLocationId: number;
  currentWarehouseLocationName?: string;
  currentWarehouseId: number;
  customerItemId: number;
  customerId: number;
  sku: string;
  moveableUnitId?: string | number | null;
  receiptId?: number;
  receiptIds?: Array<number>;
}

interface RenderProps {
  warehouseId: number | string;
  moveableUnitId?: string | number | null;
  receiptId?: number;
  receiptIds?: Array<number>;
  item: WarehouseLocationInventoryItemAPI | null;
  canEditQty: boolean;
  formik: FormikProps<State>;
  handleShowPhysicalItems: () => void;
}

type RequestType = 'ManualTransfer' | 'AssignedTransfer';

interface State {
  quantity: number | '';
  reason: string;
  receiptId: number | '';
  destinationWarehouseId: number | '';
  destinationWarehouseLocationName: string;
  moveMoveableUnit: boolean;
  requestType: RequestType;
  assigneeUserIds: Array<string | number>;
}

type View = 'details' | 'physicalItems';

const initialState: State = {
  quantity: '',
  reason: '',
  receiptId: '',
  destinationWarehouseId: '',
  destinationWarehouseLocationName: '',
  moveMoveableUnit: true,
  requestType: 'ManualTransfer',
  assigneeUserIds: [],
};

export function TransferInventoryItemForm({
  open,
  handleClose,
  receiptId,
  receiptIds,
  currentWarehouseId,
  currentWarehouseLocationName,
  currentWarehouseLocationId,
  customerItemId,
  customerId,
  sku,
  moveableUnitId,
}: TransferInventoryItemProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const isMobile = useIsMobile();

  const [view, setView] = useState<View>('details');
  const [item, setItem] = useState<WarehouseLocationInventoryItemAPI | null>(
    null,
  );
  const [submitting, setSubmitting] = useState(false);
  const [muPhysicalItemsToTransfer, setMuPhysicalItemsToTransfer] =
    useState<AddPhysicalItemsToWarehouseLocationRequest | null>(null);

  useEffect(() => {
    dispatch(getBasicWarehouses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getInventoryItemDetails(
          currentWarehouseLocationId ?? currentWarehouseLocationName,
          customerItemId,
          {
            moveableUnitID: moveableUnitId ? moveableUnitId : undefined,
            receiptID: receiptId,
            warehouseID: currentWarehouseId,
          },
        ),
      );
      if (data) {
        setItem(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values: State) => {
      const {
        requestType,
        destinationWarehouseId,
        quantity,
        destinationWarehouseLocationName,
        moveMoveableUnit,
        reason,
        receiptId: transferReceiptId,
        assigneeUserIds,
      } = values;
      setSubmitting(true);
      if (requestType === 'ManualTransfer') {
        const payload: TransferInventoryRequest = {
          receiptID: transferReceiptId || undefined,
          currentWarehouseLocationID: currentWarehouseLocationId,
          currentWarehouseLocationName,
          currentWarehouseID: currentWarehouseId,
          customerItemID: customerItemId,
          customerID: customerId,
          sku,
          moveableUnitID: moveMoveableUnit ? moveableUnitId : undefined,
          quantity: quantity || undefined,
          reason,
          destinationWarehouseID: destinationWarehouseId || undefined,
          destinationWarehouseLocationName,
          physicalItemsToTransfer: muPhysicalItemsToTransfer,
        };

        const { error } = await dispatch(transferInventoryItem(payload, true));
        setSubmitting(false);
        if (!error) {
          refresh();
          handleClose();
        }
      } else if (requestType === 'AssignedTransfer') {
        const payload: InventoryTransferJobRequest = {
          type: 'MoveItem',
          warehouseID: currentWarehouseId,
          customerID: customerId,
          note: reason,
          originLocations: [
            {
              warehouseLocationID: currentWarehouseLocationId,
              warehouseLocationName: currentWarehouseLocationName,
              moveableUnitID: moveMoveableUnit ? moveableUnitId : undefined,
              type: 'Origin',
              taskType: 'MoveInventoryItem',
              totalPrimaryUnitQty: !moveMoveableUnit
                ? quantity || 0
                : undefined,
              customerItemID: customerItemId,
              sku,
            },
          ],
          destinationLocations: [
            {
              warehouseLocationName: destinationWarehouseLocationName,
              type: 'Destination',
              taskType: 'MoveInventoryItem',
              totalPrimaryUnitQty: !moveMoveableUnit
                ? quantity || 0
                : undefined,
              customerItemID: customerItemId,
              sku,
              moveableUnitID: moveMoveableUnit ? moveableUnitId : undefined,
            },
          ],
          assignees: assigneeUserIds.map((u) => ({
            userID: u,
            taskType: 'MoveInventoryItem',
          })),
        };
        const { error } = await dispatch(
          assignTransferInventoryItem(payload, true),
        );
        setSubmitting(false);
        if (!error) {
          refresh();
          handleClose();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentWarehouseId,
      currentWarehouseLocationId,
      currentWarehouseLocationName,
      customerId,
      customerItemId,
      moveableUnitId,
      muPhysicalItemsToTransfer,
      sku,
    ],
  );

  const formik = useFormik<State>({
    initialValues: {
      ...initialState,
      destinationWarehouseId: currentWarehouseId || '',
      receiptId: receiptId || '',
      moveMoveableUnit: !!moveableUnitId,
    },
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const handleShowDetails = useCallback(() => {
    setView('details');
  }, []);

  const handleShowPhysicalItems = useCallback(() => {
    setView('physicalItems');
  }, []);

  const handleGetPhysicalItemsToTransfer = useCallback(
    (payload: AddPhysicalItemsToWarehouseLocationRequest) => {
      setMuPhysicalItemsToTransfer(payload);
      setView('details');
    },
    [],
  );

  const canEditQty = !formik.values.moveMoveableUnit;

  if (isMobile) {
    return (
      <MobileModalForm
        open={open}
        handleClose={handleClose}
        callback={formik.handleSubmit}
        disableSubmit={submitting}
        btnText='Transfer'
        submitting={submitting}
        title='Move item'
      >
        {view === 'physicalItems' &&
        currentWarehouseLocationId &&
        customerItemId &&
        formik.values.receiptId ? (
          <InventoryPhysicalItems
            receiptId={formik.values.receiptId}
            warehouseLocationId={currentWarehouseLocationId}
            moveableUnitId={moveableUnitId}
            customerItemId={customerItemId}
            sku={sku}
            handleBack={handleShowDetails}
            canEdit
            handleSubmitOverride={handleGetPhysicalItemsToTransfer}
          />
        ) : (
          <RenderTransferForm
            formik={formik}
            warehouseId={currentWarehouseId}
            moveableUnitId={moveableUnitId}
            receiptIds={receiptIds}
            receiptId={receiptId}
            item={item}
            canEditQty={canEditQty}
            handleShowPhysicalItems={handleShowPhysicalItems}
          />
        )}
      </MobileModalForm>
    );
  }

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Move item</span>}
      callback={formik.handleSubmit}
      btnText='TRANSFER'
      typeSubmit
      maxWidth='md'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      {view === 'physicalItems' &&
      currentWarehouseLocationId &&
      customerItemId &&
      formik.values.receiptId ? (
        <InventoryPhysicalItems
          receiptId={formik.values.receiptId}
          warehouseLocationId={currentWarehouseLocationId}
          moveableUnitId={moveableUnitId}
          customerItemId={customerItemId}
          sku={sku}
          handleBack={handleShowDetails}
          canEdit
          handleSubmitOverride={handleGetPhysicalItemsToTransfer}
        />
      ) : (
        <Fragment>
          <div className={classes.locationContainer}>
            {currentWarehouseLocationName && (
              <div>{currentWarehouseLocationName}</div>
            )}
            {sku && currentWarehouseLocationName && (
              <span className={classes.dot} />
            )}
            {sku && <div className={classes.sku}>{sku}</div>}
          </div>
          <RenderTransferForm
            formik={formik}
            warehouseId={currentWarehouseId}
            moveableUnitId={moveableUnitId}
            receiptIds={receiptIds}
            receiptId={receiptId}
            item={item}
            canEditQty={canEditQty}
            handleShowPhysicalItems={handleShowPhysicalItems}
          />
        </Fragment>
      )}
    </FormModal>
  );
}

function RenderTransferForm({
  formik,
  warehouseId,
  moveableUnitId,
  receiptIds,
  receiptId,
  item,
  canEditQty,
  handleShowPhysicalItems,
}: RenderProps) {
  const { classes, css } = useStyles();
  const isMobile = useIsMobile();

  const warehouses = useSelector(warehousesSelectors.basicList);

  const [users, usersObj] = useGetUsersForSelect({
    warehouseId,
    userTypes: ['Scanner', 'ScannerProcessor'],
  });

  const {
    primaryQuantityRemaining: skuQuantity = 0,
    skuPhysicalItemsQuantity = 0,
  } = item || {};

  const maxQtyAllowedToRemove = skuQuantity - skuPhysicalItemsQuantity;

  return (
    <div>
      <div className={classes.subTitle}>
        Specify the qty and reason for moving these items. Then enter a new
        location to move it to.
      </div>
      <div className={classes.chips}>
        <ChipsInput
          formikProps={formik}
          label='How do you want to move the item?'
          name='requestType'
          chips={[
            {
              label: 'Manual transfer',
              value: 'ManualTransfer' as RequestType,
            },
            {
              label: 'Assign users',
              value: 'AssignedTransfer' as RequestType,
            },
          ]}
        />
      </div>
      <Grid container spacing={3} rowSpacing={2}>
        {!!moveableUnitId && (
          <Grid
            size={{
              sm: 12,
            }}
          >
            <CheckboxInput
              formikProps={formik}
              checkboxes={[
                {
                  labelProps: {
                    label: `Move with MU ${moveableUnitId}`,
                  },
                  checkboxProps: {
                    name: 'moveMoveableUnit',
                  },
                },
              ]}
            />
            {/* <RadioInput
              formikProps={formik}
              name='moveMoveableUnit'
              radiosRow
              title={`Move with MU ${moveableUnitId}`}
              size='small'
              radios={[
                {
                  label: 'Yes',
                  value: 'yes' as ShouldMoveType,
                },
                { label: 'No', value: 'no' as ShouldMoveType },
              ]}
            /> */}
          </Grid>
        )}
        {canEditQty && (
          <Grid
            size={{
              sm: isMobile ? 12 : 3,
            }}
          >
            <TextInput
              name='quantity'
              formikProps={formik}
              label='Qty'
              className={classes.input}
              type='number'
              slotProps={{
                htmlInput: {
                  max: skuQuantity,
                  min: 0,
                },
                input: {
                  endAdornment:
                    skuPhysicalItemsQuantity > 0 ? (
                      <Tooltip
                        title={`The qty can’t be more than ${
                          maxQtyAllowedToRemove
                        } since there are ${skuQuantity} items for this SKU and ${skuPhysicalItemsQuantity} physical items created. To remove it, you must also remove the physical items in the inventory tracking screen.`}
                      >
                        <InfoIcon
                          className={css({
                            fontSize: 16,
                            color: '#A5AABD',
                            marginLeft: 8,
                          })}
                        />
                      </Tooltip>
                    ) : undefined,
                },
              }}
            />
          </Grid>
        )}
        <Grid
          size={{
            sm: isMobile || !canEditQty ? 12 : 9,
          }}
        >
          <TextInput
            name='reason'
            formikProps={formik}
            label='Reason'
            className={classes.input}
          />
        </Grid>
        {!receiptId &&
          !!receiptIds?.length &&
          formik.values.requestType === 'ManualTransfer' && (
            <Grid
              size={{
                sm: 12,
              }}
            >
              <SelectInput
                name='receiptId'
                formikProps={formik}
                label='Select a receipt'
                options={receiptIds.map((id) => ({
                  id,
                  name: `Receipt ${id}`,
                }))}
                className={classes.input}
              />
            </Grid>
          )}
      </Grid>
      {formik.values.requestType === 'ManualTransfer' &&
        canEditQty &&
        skuPhysicalItemsQuantity > 0 &&
        formik.values.quantity !== '' && (
          <div>
            <TextButton onClick={handleShowPhysicalItems}>
              Transfer physical items
            </TextButton>
          </div>
        )}
      <div className={classes.sectionTitle}>Select a new location</div>
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          size={{
            sm: isMobile ? 12 : 6,
          }}
        >
          <SelectInput
            name='destinationWarehouseId'
            formikProps={formik}
            label='Select a warehouse'
            options={warehouses}
            className={classes.input}
          />
        </Grid>
        <Grid
          size={{
            sm: isMobile ? 12 : 6,
          }}
        >
          <TextInput
            name='destinationWarehouseLocationName'
            formikProps={formik}
            label='Enter a location'
            className={classes.input}
          />
        </Grid>
      </Grid>
      {formik.values.requestType === 'AssignedTransfer' && (
        <div>
          <div className={classes.sectionTitle}>Assign users</div>
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'assigneeUserIds',
              label: 'Assign users',
              required: true,
            }}
            autocompleteProps={{
              // className: classes.smallInput,
              options: users,
              multiple: true,
              renderTags: (tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...otherProps } = getTagProps({ index });
                  return (
                    <Chip key={key} label={usersObj[option]} {...otherProps} />
                  );
                }),
            }}
          />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles({ name: { TransferInventoryItemForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    subTitle: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      letterSpacing: 0,
      marginBottom: 16,
    },
    input: {
      width: '100%',
    },
    sectionTitle: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 0,
      marginTop: 40,
      marginBottom: 16,
      [theme.breakpoints.down('tablet')]: {
        marginTop: 16,
      },
    },
    sku: {
      color: '#00C4F8',
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0,
    },
    locationContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dot: {
      height: 8,
      width: 8,
      marginRight: 8,
      borderRadius: 10,
      background: '#000000',
      margin: '0px 8px',
    },
    chips: {
      width: 400,
    },
  }),
);

const schema = object().shape({
  destinationWarehouseId: string().required('Required'),
  destinationWarehouseLocationName: string().required('Required'),
  reason: string().required('Required'),
});
