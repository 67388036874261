import { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { MouseChangeEventType } from '../../types';
import { Dropdown } from '../../components';

interface Props {
  notification: string;
  onNotificationClose?: () => any;
  showNotificationOnFirstRender?: boolean;
  confirmButtonText?: string;
}

export const MobilePageHeaderNotification = ({
  ref,
  notification,
  onNotificationClose,
  showNotificationOnFirstRender = false,
  confirmButtonText = 'Got it',
}: Props & {
  ref?: React.RefObject<HTMLButtonElement | null>;
}) => {
  const { classes } = useStyles();
  const [showNotification, setShowNotification] = useState(false);
  const fallbackRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showNotificationOnFirstRender) {
      setShowNotification(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      onNotificationClose?.();
      setShowNotification(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <div ref={fallbackRef} />
      <Dropdown
        ref={ref || fallbackRef}
        open={showNotification}
        handleClose={() => {}}
        paperProps={{
          elevation: 0,
          className: classes.card,
        }}
        popperProps={{
          style: { zIndex: 1 },
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-24, 6],
              },
            },
          ],
        }}
      >
        <>
          <div className={classes.notification}>{notification}</div>
          <div>
            <Button
              className={classes.btn}
              onClick={handleClose}
              variant='contained'
              size='small'
            >
              {confirmButtonText}
            </Button>
          </div>
        </>
      </Dropdown>
    </>
  );
};

const useStyles = makeStyles({ name: { MobilePageHeaderNotification } })(
  (theme) => ({
    card: {
      width: 180,
      maxWidth: 180,
      backgroundColor: '#43485B',
      padding: '6px 8px 12px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: `10px solid #43485B`,
        top: '-6px',
        left: '20px',
      },
    },
    notification: {
      color: '#FFFFFF',
      fontFamily: 'Lato',
      fontSize: 12,
      letterSpacing: 0,
      marginBottom: 8,
    },
    btn: {
      color: '#FFFFFF',
      backgroundColor: 'rgb(255, 255, 255, 0.16)',
      textTransform: 'none',
      padding: '2px 4px !important',
      minWidth: 0,
      fontSize: 12,
      letterSpacing: 0,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 255, 0.16)',
      },
    },
  }),
);
