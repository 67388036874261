import {
  useState,
  useCallback,
  useRef,
  useEffect,
  Fragment,
  useMemo,
  RefObject,
} from 'react';
import { useParams } from 'react-router';
import { FormikProps, useFormik } from 'formik';
import { object, string } from 'yup';
import Webcam from 'react-webcam';
import { TransitionProps } from '@mui/material/transitions';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Slide,
  Toolbar,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Avatar,
} from '@mui/material';
import {
  authGet,
  convertNullFieldsToEmptyString,
  makeuuid,
  getDocumentsSubmitUrlAndId,
  CameraApiConfig,
  getDocumentsSubmitUrlAndIdFromServiceType,
  serviceTypesRequiringWarehouseId,
  Navigation,
  generateUrl,
  paths,
  generateDocumentsApi,
} from '../../../lib';
import {
  useDispatch,
  useSelector,
  uploadFiles,
  addResource,
  deleteResource,
  systemSelectors,
  warehousesSelectors,
} from '../../../state';
import {
  useGetUsersData,
  useIsMobile,
  useNotify,
  useRefresh,
} from '../../../hooks';
import { CheckboxInput, SelectInput, TextInput } from '../inputs';
import {
  CloseIcon,
  FlipCameraAndroidIcon,
  ChevronRightIcon,
} from '../../../assets';
import { SecondaryButton, MobileModal } from '../../../themes';
import MobileActionForm from '../../../themes/mobile/MobileActionForm';
import { DeleteConfirmation } from './DeleteConfirmation';
import { useStyles } from './cameraUpload.styles';

import type {
  DocumentAPI,
  FileType,
  MouseChangeEventType,
  RouteIdParamsTypes,
  SignedUrlMapping,
  SystemServiceType,
} from '../../../types';

type FacingMode = 'user' | 'environment';

interface Props {
  open: boolean;
  handleClose: () => any;
  documentId?: number | string;
  shouldRefresh?: boolean;
  apiConfigs?: CameraApiConfig | null;
}

interface RenderProps {
  handleClose?: () => any;
  documentId?: number | string;
  shouldRefresh?: boolean;
  apiConfigs?: CameraApiConfig | null;
  setIsFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen?: boolean;
  isModal?: boolean;
}

interface State extends DocumentAPI {
  name: string;
  visibleToCustomer?: boolean;
  systemServiceType: SystemServiceType | '';
  systemServiceId: string | number;
  warehouseId: string | number;
  isModal: boolean;
}

interface GenerateParams {
  entityField: string;
  entityId: string | number;
  files: Array<FileType>;
  signedUrlsMapping: SignedUrlMapping;
  values: State;
  hasUpload: boolean;
}

interface MobilModalProps {
  open: boolean;
  handleClose: () => any;
  formik: FormikProps<State>;
  submitting: boolean;
}

const initialState: State = {
  name: '',
  visibleToCustomer: true,
  systemServiceType: '',
  systemServiceId: '',
  warehouseId: '',
  isModal: false,
};

export function CameraUpload(props: Props) {
  const isMobile = useIsMobile();

  const [isFullscreen, setIsFullscreen] = useState(true);

  if (isMobile) {
    return (
      <RenderCameraUpload {...props} isModal isFullScreen={isFullscreen} />
    );
  }

  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // callback={formik.handleSubmit}
      // title='Camera'
      fullScreen={isFullscreen}
      slotProps={{
        paper: !isFullscreen ? { style: { width: 468 } } : undefined,
      }}
      slots={{ transition: isFullscreen ? Transition : undefined }}
      // disableSubmit={!dataUri}
      // submitting={submitting}
      // isEdit={!!documentId}
      // handleDelete={handleDelete}
      // hasCustomActionBtn={isFullscreen}
    >
      <RenderCameraUpload
        {...props}
        setIsFullscreen={setIsFullscreen}
        isFullScreen={isFullscreen}
        isModal
      />
    </Dialog>
  );
}

export function RenderCameraUpload({
  handleClose,
  documentId,
  shouldRefresh,
  apiConfigs,
  setIsFullscreen,
  isFullScreen,
  isModal,
}: RenderProps) {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const isMobile = useIsMobile();
  const routeParams: RouteIdParamsTypes = useParams();

  const ref = useRef<Webcam | null>(null);

  const [state, setState] = useState(initialState);
  const [view, setView] = useState<'Camera' | 'View' | 'Form'>(
    documentId ? 'Form' : 'Camera',
  );
  const [dataUris, setDataUris] = useState<Record<string, string>>({});
  const [submitting, setSubmitting] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [isSelfie, setIsSelfie] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    (async function () {
      const config = apiConfigs ?? getDocumentsSubmitUrlAndId(routeParams);
      if (documentId && config?.route) {
        const { data } = await authGet(`${config.route}/${documentId}`);
        if (data) {
          const { document, id, ...otherData } = data;
          const { name, ...rest } = document || {};
          setState((cur) => ({
            ...cur,
            ...rest,
            ...convertNullFieldsToEmptyString({
              name,
              ...otherData,
            }),
          }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams, documentId]);

  const handleTakePhoto = useCallback(() => {
    setIsFlashing(true);

    setTimeout(() => {
      setIsFlashing(false);
    }, 100);

    const imageSrc = ref.current?.getScreenshot();

    if (imageSrc) {
      setDataUris((cur) => ({ ...cur, [makeuuid(6)]: imageSrc }));
    }
  }, []);

  const handleReview = useCallback(() => {
    setView('View');
  }, []);

  const handleSubmit = useCallback(
    async (values: State) => {
      if (!dataUris && !documentId) return;

      let config: CameraApiConfig | null = null;
      if (values.isModal) {
        config = apiConfigs ?? getDocumentsSubmitUrlAndId(routeParams);
      } else if (values.systemServiceType && values.systemServiceId) {
        config = getDocumentsSubmitUrlAndIdFromServiceType(
          values.systemServiceType,
          values.systemServiceId,
          values.warehouseId,
        );
      }
      if (!config) return;

      setSubmitting(true);

      let googleResponse, signedUrlsMapping;
      const files: Array<FileType> = [];
      const imgUris = Object.values(dataUris);

      if (!documentId && imgUris.length) {
        const blobResponses = await Promise.all(
          imgUris.map((uri) => fetch(uri)),
        );
        const blobs = await Promise.all(
          blobResponses.map((blobResponse) => blobResponse.blob()),
        );
        blobs.forEach((blob) => {
          const objectName = `photos/${makeuuid(6)}-${values.name}.jpg`;
          const file = new File([blob], objectName, { type: 'image/jpg' });
          files.push({
            rawFile: file,
            key: objectName,
          });
        });

        const { data, error: uploadError } = await dispatch(uploadFiles(files));

        if (uploadError) {
          return setSubmitting(false);
        }
        googleResponse = data?.googleResponse;
        signedUrlsMapping = data?.signedUrlsMapping;
      }

      if (documentId || googleResponse) {
        const { idField, idValue, route } = config;
        const payload = generatePayload({
          entityField: idField,
          entityId: idValue,
          files,
          signedUrlsMapping,
          values,
          hasUpload: !documentId,
        });
        const { error } = await dispatch(
          addResource({
            baseUrl: route,
            payload,
            message: 'Changes saved',
          }),
        );
        setSubmitting(false);
        if (!error) {
          if (!isModal && isMobile) {
            Navigation.redirect(generateUrl(paths.MOBILE_HOME));
          } else {
            shouldRefresh && refresh();
            handleClose?.();
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataUris, routeParams, shouldRefresh],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state, isModal: isModal === true },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const uriKeys = Object.keys(dataUris);
  const imagesCount = uriKeys.length;

  const lastImage = useMemo(() => {
    if (imagesCount) {
      return dataUris[uriKeys[imagesCount - 1]];
    }
    return null;
  }, [dataUris, imagesCount, uriKeys]);

  return (
    <div
      className={cx(classes.layout, {
        [classes.mobileModal]: isModal && isMobile,
      })}
    >
      {isFlashing && <div className={classes.flash} />}
      <Fragment>
        {isFullScreen && (
          <Fragment>
            <AppBar sx={{ position: 'fixed' }} elevation={0}>
              <Toolbar>
                <IconButton edge='start' color='inherit' onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className={classes.barSpacer} />
          </Fragment>
        )}
        {view === 'Camera' && (
          <CameraView
            ref={ref}
            handleTakePhoto={handleTakePhoto}
            handleReview={handleReview}
            lastImage={lastImage}
            isSelfie={isSelfie}
            setIsSelfie={setIsSelfie}
          />
        )}
        {view === 'View' && dataUris && (
          <PhotoView
            dataUris={dataUris}
            isModal={isModal}
            uriKeys={uriKeys}
            setDataUris={setDataUris}
            setIsFullscreen={setIsFullscreen}
            setShowMobileModal={setShowMobileModal}
            setView={setView}
          />
        )}
        {view === 'Form' && (
          <PhotoForm
            apiConfigs={apiConfigs}
            dataUris={dataUris}
            documentId={documentId}
            formik={formik}
            handleClose={handleClose}
            routeParams={routeParams}
          />
        )}
      </Fragment>
      {showMobileModal && (
        <RenderMobileForm
          open={showMobileModal}
          handleClose={() => setShowMobileModal(false)}
          formik={formik}
          submitting={submitting}
        />
      )}
    </div>
  );
}

function CameraView({
  ref,
  handleTakePhoto,
  handleReview,
  isSelfie,
  lastImage,
  setIsSelfie,
}) {
  const { classes, css } = useStyles();
  const notify = useNotify();

  const [rotation, setRotation] = useState(0);

  const handleCameraError = useCallback((error: string | DOMException) => {
    const message =
      typeof error === 'string' ? error : (error.message ?? error.name);
    notify(message ?? 'Something went wrong', 'error');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleFacingMode = useCallback(() => {
    setRotation((cur) => cur + 90);
    setIsSelfie((cur) => !cur);
  }, [setIsSelfie]);

  return (
    <div
      className={css({
        height: '75vh',
        width: '100%',
        // position: 'relative',
      })}
    >
      <Webcam
        ref={ref}
        onUserMediaError={handleCameraError}
        mirrored={isSelfie}
        screenshotFormat='image/jpeg'
        screenshotQuality={0.8}
        className={css({
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        })}
        videoConstraints={{
          facingMode: (isSelfie ? 'user' : 'environment') as FacingMode,
        }}
      />
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
        })}
      >
        <IconButton
          onClick={handleToggleFacingMode}
          style={{ transform: `rotate(${rotation}deg)` }}
        >
          <FlipCameraAndroidIcon />
        </IconButton>
        <IconButton onClick={handleTakePhoto}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle} />
          </div>
        </IconButton>
        {lastImage ? (
          <div className={css({ display: 'flex', alignItems: 'center' })}>
            <Avatar src={lastImage} variant='square' sizes='large' />
            <IconButton size='small' onClick={handleReview}>
              <ChevronRightIcon />
            </IconButton>
          </div>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}

function PhotoView({
  dataUris,
  isModal,
  setDataUris,
  setIsFullscreen,
  setShowMobileModal,
  setView,
  uriKeys,
}) {
  const { classes, css, cx } = useStyles();

  const handleRemovePhoto = useCallback(
    (e: MouseChangeEventType) => {
      const key = e.currentTarget.getAttribute('data-key');
      if (key) {
        setDataUris((cur) => {
          const { [key]: _, ...newUris } = cur;
          return newUris;
        });
      }
    },
    [setDataUris],
  );

  const handleConfirmPhoto = useCallback(() => {
    if (isModal) {
      setView('Form');
      setIsFullscreen?.(isModal);
    } else {
      setShowMobileModal(true);
    }
  }, [isModal, setIsFullscreen, setShowMobileModal, setView]);

  const handleRetakePhoto = useCallback(() => {
    setDataUris({});
    setView('Camera');
    if (isModal) {
      setIsFullscreen?.(true);
    }
  }, [isModal, setDataUris, setIsFullscreen, setView]);

  return (
    <div className={cx(classes.viewPadding, classes.viewLayout)}>
      <ImageList cols={3} rowHeight={164}>
        {uriKeys.map((key) => {
          const imgUri = dataUris[key];
          return (
            <ImageListItem key={key}>
              <img src={imgUri} alt={imgUri} loading='lazy' />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                position='top'
                actionIcon={
                  <IconButton
                    sx={{ color: 'white' }}
                    onClick={handleRemovePhoto}
                    data-key={key}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                actionPosition='left'
              />
            </ImageListItem>
          );
        })}
      </ImageList>
      {/* <img src={dataUris} className={classes.img} /> */}
      <DialogActions className={css({ marginTop: 20 })}>
        <Button
          variant='contained'
          size='large'
          disableElevation
          onClick={handleRetakePhoto}
          className={cx(classes.btn, classes.retakeBtn)}
        >
          RETAKE
        </Button>
        <Button
          variant='contained'
          size='large'
          disableElevation
          onClick={handleConfirmPhoto}
          className={classes.btn}
        >
          USE PHOTOS
        </Button>
      </DialogActions>
    </div>
  );
}

function PhotoForm({
  apiConfigs,
  dataUris,
  documentId,
  formik,
  handleClose,
  routeParams,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { isCustomer } = useGetUsersData();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = useCallback(async () => {
    const config = apiConfigs ?? getDocumentsSubmitUrlAndId(routeParams);
    if (!documentId || !config?.route) return;
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: config.route,
        id: documentId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, routeParams]);

  return (
    <div className={classes.viewPadding}>
      <div className={classes.title}>Save photo</div>
      <form onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
        <div className={classes.formContainer}>
          <TextInput
            name='name'
            formikProps={formik}
            label='Name'
            className={classes.input}
            required
          />
          {!isCustomer && (
            <CheckboxInput
              formikProps={formik}
              checkboxes={[
                {
                  labelProps: {
                    label: 'Visible to customer',
                  },
                  checkboxProps: {
                    name: 'visibleToCustomer',
                  },
                },
              ]}
            />
          )}
        </div>
        <DialogActions>
          <div style={{ marginRight: 'auto' }}>
            {!!documentId && (
              <Button
                className={classes.deleteBtn}
                onClick={() => setShowDeleteConfirm(true)}
                disabled={submitting}
              >
                Delete
              </Button>
            )}
          </div>
          <SecondaryButton
            variant='contained'
            size='large'
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </SecondaryButton>
          <Button
            variant='contained'
            size='large'
            disableElevation
            type='submit'
            className={classes.btn}
            disabled={!dataUris || submitting}
          >
            SAVE
          </Button>
        </DialogActions>
      </form>
      {showDeleteConfirm && (
        <DeleteConfirmation
          handleClose={() => setShowDeleteConfirm(false)}
          title='Photo'
          handleDelete={handleDelete}
          open={showDeleteConfirm}
          text='Are you sure you want to delete?'
        />
      )}
    </div>
  );
}

const Transition = (
  props: TransitionProps & {
    children: React.ReactElement<any>;
    ref?: RefObject<unknown>;
  },
) => {
  return <Slide direction='up' {...props} />;
};

function RenderMobileForm({
  open,
  handleClose,
  formik,
  submitting,
}: MobilModalProps) {
  const { classes } = useStyles();
  const systemServiceTypes = useSelector(systemSelectors.systemServiceTypes);
  const warehouses = useSelector(warehousesSelectors.basicList);
  const requiresWarehouse =
    formik.values.systemServiceType &&
    serviceTypesRequiringWarehouseId.includes(formik.values.systemServiceType);

  return (
    <MobileModal
      open={open}
      handleClose={handleClose}
      // onClose={handleClose}
      // onOpen={handleOpen}
    >
      <MobileActionForm
        title='Save photos'
        handleCancel={handleClose}
        callback={formik.handleSubmit}
        typeSubmit
        submitting={submitting}
      >
        <TextInput
          name='name'
          formikProps={formik}
          label='Name'
          required
          className={classes.input}
        />
        <SelectInput
          name='systemServiceType'
          formikProps={formik}
          label='Select service'
          options={systemServiceTypes}
          required
          className={classes.input}
        />
        <TextInput
          name='systemServiceId'
          formikProps={formik}
          label='Service ID'
          required
          className={classes.input}
        />
        {requiresWarehouse && (
          <SelectInput
            name='warehouseId'
            formikProps={formik}
            label='Warehouse ID'
            options={warehouses}
            required
            className={classes.input}
          />
        )}
      </MobileActionForm>
    </MobileModal>
  );
}

function generatePayload({
  entityField,
  entityId,
  files,
  signedUrlsMapping,
  values,
  hasUpload,
}: GenerateParams) {
  const {
    description,
    id,
    name,
    objectName,
    type,
    url,
    visibleToCustomer = true,
  } = values;

  return {
    [entityField]: entityId,
    billableToCustomer: false,
    quantity: 1,
    documents: hasUpload
      ? generateDocumentsApi({
          signedUrlsMapping,
          files,
          name,
          visibleToCustomer,
          isPhoto: true,
        }).map(({ document }) => document)
      : [
          {
            description,
            id,
            isPhoto: true,
            name,
            objectName,
            type,
            url,
            visibleToCustomer,
          },
        ],
  };
}

const schema = object().shape({
  name: string().nullable().required().typeError('Required'),
  systemServiceId: string()
    .nullable()
    .when('isModal', {
      is: false,
      then: string().required('Required'),
    }),
  systemServiceType: string()
    .nullable()
    .when('isModal', {
      is: false,
      then: string().required('Required'),
    }),
  warehouseId: string()
    .nullable()
    .when('systemServiceType', (type: SystemServiceType, schema) => {
      return serviceTypesRequiringWarehouseId.includes(type)
        ? schema.required('Required')
        : schema;
    }),
});
