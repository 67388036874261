import { useCallback, useState, useEffect, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Chip, Grid2 } from '@mui/material';
import { FormModal } from './FormModal';
import {
  AutocompleteInput,
  // AutocompleteInput,
  CheckboxInput,
  SelectInput,
  TextInput,
} from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
  useSelector,
  systemSelectors,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';
import {
  SelectOption,
  WarehouseLocationAPI,
  WarehouseLocationType,
  WarehouseStagingAreaType,
  WarehouseStorageCategoryType,
  WarehouseVehicleType,
} from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  aisleId: number | string;
  position?: number | string;
  locationId?: number | string;
  locationType: WarehouseLocationType;
  aisleNumber?: string | number;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface State
  extends Omit<
    WarehouseLocationAPI,
    | 'warehouseID'
    | 'accessibilityType'
    | 'category'
    | 'stagingCategory'
    | 'type'
  > {
  // freeTextPosition: string;
  category: WarehouseStorageCategoryType | '';
  stagingCategory: WarehouseStagingAreaType | '';
  type: WarehouseLocationType | '';
}

const initialState: State = {
  type: '',
  position: '',
  // freeTextPosition: '',
  level: '',
  externalID: '',
  category: '',
  stagingCategory: '',
  capableWeightPounds: '',
  actualLengthInches: '',
  actualWidthInches: '',
  actualHeightInches: '',
  capableLengthInches: '',
  capableWidthInches: '',
  capableHeightInches: '',
  isEasyToAccess: false,
  vehicleTypes: [],
};

export function WarehouseLocationForm({
  open,
  handleClose,
  warehouseId,
  aisleId,
  aisleNumber,
  position,
  locationId,
  locationType,
  setViewVersion,
}: Props) {
  const { classes, css } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [vehicleTypes, setVehicleTypes] = useState<
    Array<SelectOption<WarehouseVehicleType>>
  >([]);
  const [vehiclesObj, setVehiclesObj] = useState<Record<string, string>>({});
  // const [positionOptions, setPositionOptions] = useState<Array<IdNamePair>>([]);

  const locationCategories = useSelector(
    systemSelectors.warehouseStorageCategoryTypes,
  );

  const stagingCategories = useSelector(
    systemSelectors.warehouseStagingAreaTypes,
  );

  const locationTypes = useSelector(systemSelectors.warehouseLocationTypes);

  const _vehicleTypes = useSelector(systemSelectors.warehouseVehicleTypes);

  useEffect(() => {
    if (_vehicleTypes) {
      const filtered = _vehicleTypes.filter((v) => v.id !== 'Other');
      setVehicleTypes(filtered);
      const obj = filtered.reduce(
        (acc, cur) => {
          acc[cur.id] = cur.name;
          return acc;
        },
        {} as Record<string, string>,
      );
      setVehiclesObj(obj);
    }
  }, [_vehicleTypes]);

  useEffect(() => {
    (async function () {
      if (aisleId && position && locationId) {
        const { data } = await dispatch(
          fetchResourceById<WarehouseLocationAPI>({
            baseUrl: '/warehouses',
            id: warehouseId,
            path: `aisles/${aisleId}/positions/${position}/locations/${locationId}`,
          }),
        );
        if (data) {
          const { accessibilityType, ...rest } = data;
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(rest),
            isEasyToAccess: accessibilityType === 'Easy',
          }));
        }
      } else {
        setState((cur) => ({
          ...cur,
          type: locationType,
          position: position || '',
        }));
      }
    })();
  }, [warehouseId, dispatch, aisleId, position, locationId, locationType]);

  // useEffect(() => {
  //   (async function () {
  //     if (!position) {
  //       const { data } = await authGet<Array<IdNamePair>>(
  //         `/warehouses/${warehouseId}/aisles/${aisleId}/positions/basic`,
  //       );
  //       if (data) {
  //         setPositionOptions(data);
  //       }
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const {
        isEasyToAccess,
        category,
        stagingCategory,
        type,
        // position,
        // freeTextPosition,
        ...rest
      } = values;

      if (!type) {
        return;
      }

      const payload: WarehouseLocationAPI = {
        ...rest,
        id: values.id || null,
        warehouseID: warehouseId,
        type,
        // position: position || freeTextPosition,
        accessibilityType: isEasyToAccess ? 'Easy' : 'Hard',
        category: category || undefined,
        stagingCategory: stagingCategory || undefined,
      };
      const { error } = await dispatch(
        addResource<WarehouseLocationAPI>({
          baseUrl: `/warehouses/${warehouseId}/aisles/${aisleId}/locations`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion && setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId, aisleId],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `aisles/${aisleId}/positions/${position}/locations/${locationId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion && setViewVersion((cur) => ++cur);
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, aisleId, position, locationId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  // const { handleChange, ...formikProps } = formik;
  // const { setFieldValue } = formik;

  // const customOnChange = async (e: InputChangeEventType) => {
  //   const { name } = e.target;

  //   if (name === 'position') {
  //     setFieldValue('freeTextPosition', '');
  //   } else if (name === 'freeTextPosition') {
  //     setFieldValue('position', '');
  //   }

  //   handleChange(e);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // };

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`Warehouse location ${aisleNumber}${
          !!position ? '-' + position : ''
        }`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={!!locationId}
      handleDelete={handleDelete}
    >
      <div className={classes.layout}>
        {!position && (
          <Fragment>
            <div className={classes.subSectionHeader} style={{ marginTop: 0 }}>
              Aisle position info
            </div>
            <Grid2 container spacing={5} rowSpacing={2}>
              <Grid2
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 12,
                }}
              >
                <TextInput
                  name='position'
                  formikProps={formik}
                  label='Enter new position'
                  className={classes.input}
                  type='number'
                  required
                />
              </Grid2>
            </Grid2>
          </Fragment>
        )}
        {/* {!position && (
          <Fragment>
            <div className={classes.subSectionHeader} style={{ marginTop: 0 }}>
              Aisle position info
            </div>
            <Grid2 container spacing={5} rowSpacing={2}>
              <Grid2 sm={6} style={{ paddingBottom: 0 }}>
                <AutocompleteInput
                  formikProps={formikProps}
                  textFieldProps={{
                    name: 'position',
                    label: 'Select position',
                    onChange: customOnChange,
                    required: !formik.values.freeTextPosition,
                  }}
                  autocompleteProps={{
                    className: classes.input,
                    options: positionOptions,
                  }}
                />
              </Grid2>
              <Grid2 sm={6} style={{ paddingBottom: 0 }}>
                <TextInput
                  name='freeTextPosition'
                  formikProps={formikProps}
                  handleChange={customOnChange}
                  useCustomValue
                  label='Enter new position'
                  className={classes.input}
                  type='number'
                  required={!formik.values.position}
                />
              </Grid2>
            </Grid2>
          </Fragment>
        )} */}
        <div
          className={classes.subSectionHeader}
          style={{ marginTop: !!position ? 0 : undefined }}
        >
          Location info
        </div>
        <Grid2 container spacing={5} rowSpacing={2}>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='level'
              formikProps={formik}
              label='Level'
              className={classes.input}
              required={locationType === 'LongTermStorage'}
            />
          </Grid2>
          {!!locationId && (
            <Grid2
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                name='type'
                formikProps={formik}
                label='Location type'
                className={classes.input}
                options={locationTypes}
              />
            </Grid2>
          )}
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='category'
              formikProps={formik}
              label='Storage category'
              className={classes.input}
              options={locationCategories}
            />
          </Grid2>
          {locationType === 'Staging' && (
            <Grid2
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                name='stagingCategory'
                formikProps={formik}
                label='Staging category'
                className={classes.input}
                options={stagingCategories}
              />
            </Grid2>
          )}
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='externalID'
              formikProps={formik}
              label='Reference #'
              className={classes.input}
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='capableWeightPounds'
              formikProps={formik}
              label='Weight capabilities'
              className={classes.input}
              type='number'
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'vehicleTypes',
                label: 'Which vehicles are needed to access location?',
              }}
              autocompleteProps={{
                // className: classes.smallInput,
                options: vehicleTypes,
                multiple: true,
                renderTags: (tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { key, ...otherProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        label={vehiclesObj[option]}
                        {...otherProps}
                      />
                    );
                  }),
              }}
            />
          </Grid2>
        </Grid2>
        <div className={classes.subSectionHeader}>Actual dimensions</div>
        <Grid2 container spacing={5} rowSpacing={2}>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='actualLengthInches'
              formikProps={formik}
              label='Length'
              className={classes.input}
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='actualWidthInches'
              formikProps={formik}
              label='Width'
              className={classes.input}
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='actualHeightInches'
              formikProps={formik}
              label='Height'
              className={classes.input}
            />
          </Grid2>
        </Grid2>
        <div className={classes.subSectionHeader}>Capable dimensions</div>
        <Grid2 container spacing={5} rowSpacing={2}>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='capableLengthInches'
              formikProps={formik}
              label='Length'
              className={classes.input}
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='capableWidthInches'
              formikProps={formik}
              label='Width'
              className={classes.input}
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='capableHeightInches'
              formikProps={formik}
              label='Height'
              className={classes.input}
            />
          </Grid2>
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <div className={css({ marginTop: 24 })}>
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Easy to access',
                    },
                    checkboxProps: {
                      name: 'isEasyToAccess',
                    },
                  },
                ]}
              />
            </div>
          </Grid2>
        </Grid2>
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { WarehouseLocationForm } })((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
  subSectionHeader: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 24,
    marginTop: 50,
  },
}));

const schema = object().shape(
  {
    position: string().nullable().required('Required'),
    // position: string()
    //   .nullable()
    //   .when('freeTextPosition', (freeTextPosition, schema) => {
    //     return !freeTextPosition ? schema.required('Required') : schema;
    //   }),
    // freeTextPosition: string()
    //   .nullable()
    //   .when('position', (position, schema) => {
    //     return !position ? schema.required('Required') : schema;
    //   }),
    level: string()
      .nullable()
      .when('type', {
        is: 'LongTermStorage',
        then: (schema) => schema.required('Required'),
      }),
  },
  // [['freeTextPosition', 'position']],
);
