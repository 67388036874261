import _setWith from 'lodash.setwith';
import _cloneDeep from 'lodash.clonedeep';
import _get from 'lodash.get';
import { lazy, object, mixed, ObjectSchema } from 'yup';
import { isObject, makeuuid } from '../utils';
import { AnyObject } from 'yup/lib/types';
import { FormikErrors } from 'formik';

type setState =
  | React.Dispatch<React.SetStateAction<Record<string, any>>>
  | ((
      values: React.SetStateAction<any>,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<any>>);

interface Add {
  state: Record<string, any>;
  defaultValues: Record<string, any>;
  setState: setState;
  nestedObjectKey: string | Array<string>;
  keyToAdd?: string;
}

interface Update {
  state: Record<string, any>;
  nestedKey: string | Array<string>;
  value: Record<string, any> | number | string | boolean;
  shouldOverrideValue?: boolean;
}

interface Remove {
  state: Record<string, any>;
  setState: setState;
  nestedObjectKey: string;
  keyToRemove: string;
}

export function handleAddNestedState({
  defaultValues,
  state,
  nestedObjectKey,
  setState,
  keyToAdd,
}: Add) {
  const id = keyToAdd ?? makeuuid(7);
  const nestedKeyArray = Array.isArray(nestedObjectKey)
    ? nestedObjectKey
    : [nestedObjectKey];

  const newState = updateNestedState({
    nestedKey: [...nestedKeyArray, id],
    value: { ...defaultValues },
    state,
  });
  setState(newState);
}

export function updateNestedState({
  nestedKey: _nestedKey,
  value,
  state,
  shouldOverrideValue,
}: Update) {
  const nestedKey = Array.isArray(_nestedKey)
    ? _nestedKey.map((k) => k + '')
    : _nestedKey + '';
  const cloned = _cloneDeep(state);
  const currentValue = _get(cloned, nestedKey);
  const newValue =
    !!currentValue &&
    isObject(currentValue) &&
    typeof value === 'object' &&
    !shouldOverrideValue
      ? { ...currentValue, ...value }
      : value;
  return _setWith(cloned, nestedKey, newValue, Object);
}

export function handleRemoveNestedState({
  state,
  nestedObjectKey,
  keyToRemove,
  setState,
}: Remove) {
  const { [keyToRemove]: idToRemove, ...rest } = state[nestedObjectKey];
  const newState = {
    ...state,
    [nestedObjectKey]: rest,
  };
  setState(newState);
}

export function createNestedSchema(nestedSchema: ObjectSchema<AnyObject>) {
  return lazy((values) => {
    if (isObject(values)) {
      const keys = Object.keys(values);
      if (keys.length) {
        const validators = keys.reduce((acc, key) => {
          acc[key] = nestedSchema;
          return acc;
        }, {});

        return object().shape(validators);
      }
    }
    return mixed().notRequired();
  });
}
