import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, AutocompleteInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  deleteResource,
  fetchResourceById,
  systemSelectors,
} from '../../../state';
import {
  creditCardProcessingFeeTypes,
  convertNullFieldsToEmptyString,
  getFeeAmountType,
} from '../../../lib';
import { DocumentFeeType, FeeAPI, FeeType, SelectOption } from '../../../types';

interface Props {
  handleClose: () => any;
  open: boolean;
  customerId: string | number;
  isCreditCardFee?: boolean;
  feeId?: number;
  setViewVersion: React.Dispatch<React.SetStateAction<number>>;
  feeType?: FeeType;
}

interface State
  extends Omit<
    FeeAPI,
    'name' | 'type' | 'documentFeeType' | 'amount' | 'percentage' | 'min'
  > {
  name: string;
  type: FeeType | '';
  documentFeeType: DocumentFeeType | '';
  amount: number | '';
  percentage: number | '';
  min: number | '';
}

const initialState: State = {
  name: '',
  type: '',
  documentFeeType: '',
  amount: '',
  percentage: '',
  min: '',
};

export function CustomerFeeForm({
  open,
  handleClose,
  customerId,
  feeId,
  feeType,
  setViewVersion,
  isCreditCardFee,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [feeOptions, setFeeOptions] = useState<Array<SelectOption<FeeType>>>(
    [],
  );
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<State>(initialState);

  const customerFeeTypes = useSelector(systemSelectors.customerFeeTypes);
  const documentFeeTypes = useSelector(systemSelectors.documentFeeTypes);
  const creditCardProcessingFeeTypes = useSelector(
    systemSelectors.creditCardProcessingFeeTypes,
  );

  useEffect(() => {
    if (isCreditCardFee) {
      setFeeOptions(creditCardProcessingFeeTypes);
    } else if (customerFeeTypes.length) {
      setFeeOptions(customerFeeTypes.filter((f) => f.id !== 'DrayageFee'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFeeTypes.length, isCreditCardFee]);

  useEffect(() => {
    (async function () {
      if (feeId) {
        const { data } = await dispatch(
          fetchResourceById<FeeAPI>({
            baseUrl: '/customers',
            id: customerId,
            path: `fees/${feeId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      } else if (isCreditCardFee) {
        setState((cur) => ({ ...cur, type: feeType || '' }));
      }
    })();
  }, [customerId, dispatch, feeId, feeType, isCreditCardFee]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = {
        ...values,
        id: values.id || null,
        generateExcessFee:
          !values.id && values.type === 'FloorLoadedInboundFee',
        documentFeeType:
          values.type === 'OrderDocumentFee' ? values.documentFeeType : null,
        min: values.min || null,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/fees`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [customerId, dispatch, handleClose, setViewVersion],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/customers',
        id: customerId,
        path: `fees/${feeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion((cur) => ++cur);
      handleClose();
    }
  }, [customerId, dispatch, feeId, handleClose, setViewVersion]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const isFeeTypeAmount = getFeeAmountType(formik.values.type) === 'amount';

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${feeId ? 'Edit' : 'Add'} fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!feeId}
      handleDelete={handleDelete}
    >
      {!isCreditCardFee && (
        <TextInput
          name='name'
          formikProps={formik}
          label='Fee name'
          className={classes.input}
        />
      )}
      <AutocompleteInput
        formikProps={formik}
        textFieldProps={{
          name: 'type',
          label: 'Fee type',
          className: classes.input,
          disabled: isCreditCardFee && !feeId,
          required: true,
        }}
        autocompleteProps={{
          options: feeOptions,
        }}
      />
      {formik.values.type === 'OrderDocumentFee' && (
        <SelectInput
          name='documentFeeType'
          formikProps={formik}
          options={documentFeeTypes}
          label='Document fee type'
          className={classes.input}
          required
        />
      )}
      <TextInput
        name={isFeeTypeAmount ? 'amount' : 'percentage'}
        formikProps={formik}
        label='Rate'
        className={classes.input}
        format={isFeeTypeAmount ? 'currency' : undefined}
        slotProps={{
          input: {
            endAdornment: !isFeeTypeAmount ? <span>%</span> : undefined,
          },
        }}
        required
      />
      {hasMinAmount(formik.values.type) && (
        <TextInput
          name='min'
          formikProps={formik}
          label='Minimum amount'
          className={classes.input}
          format='currency'
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  type: string().required('Required'),
  documentFeeType: string().when('type', {
    is: 'OrderDocumentFee',
    then: string().required('Required'),
  }),
  percentage: string().when('type', (type, schema) => {
    return type === 'CarrierPercentageFee' || isCCProcessingFeeType(type)
      ? schema.required('Required')
      : schema;
  }),
  amount: string().when('type', (type, schema) => {
    return type !== 'CarrierPercentageFee' && !isCCProcessingFeeType(type)
      ? schema.required('Required')
      : schema;
  }),
});

const isCCProcessingFeeType = (type: FeeType) => {
  return (
    [
      creditCardProcessingFeeTypes.VISA,
      creditCardProcessingFeeTypes.MASTER_CARD,
      creditCardProcessingFeeTypes.AMERICAN_EXPRESS,
      creditCardProcessingFeeTypes.DISCOVER,
      creditCardProcessingFeeTypes.DINERS,
      creditCardProcessingFeeTypes.JCB,
    ] as Array<FeeType>
  ).includes(type);
};

const hasMinAmount = (type: FeeType | '') => {
  return (
    type &&
    (['ItemDisposalFee', 'ReturnItemDisposalFee'] as Array<FeeType>).includes(
      type,
    )
  );
};
