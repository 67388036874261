import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
// import { useStyles } from './inputs.styles';

/**
 *
 * @param {object} props
 * @param {import("formik").FormikProps} [props.formikProps]
 * @param {(e?: any) => any} [props.handleChange]
 * @param {string} props.name
 * @param {boolean} [props.value]
 * @param {string} [props.label]
 * @param {boolean} [props.disabled]
 * @param {import("@mui/material/Switch").SwitchProps} [props.switchProps]
 * @returns
 */
export function SwitchInput({
  formikProps,
  handleChange = undefined,
  name,
  value = undefined,
  label,
  useCustomValue = false,
  disabled = false,
  switchProps,
}) {
  // const { classes } = useStyles();
  const { handleChange: formikHandleChange, values } = formikProps || {};

  const onChange = useCallback(
    (e) => {
      formikHandleChange && !useCustomValue && formikHandleChange(e);
      handleChange && handleChange(e);
    },
    [formikHandleChange, handleChange, useCustomValue],
  );

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            color='primary'
            checked={
              formikProps !== undefined && !useCustomValue
                ? values[name]
                : value
            }
            onChange={onChange}
            name={name}
            label={label}
            disabled={disabled}
            {...(switchProps ?? {})}
          />
        }
        label={label}
      />
    </div>
  );
}
