import React, { useEffect, useState } from 'react';
import {
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  PaperProps,
  GrowProps,
  PopperProps,
} from '@mui/material';
import { stableObject } from '../../lib';

import { makeStyles } from 'tss-react/mui';

interface DropdownProps {
  ref?: React.RefObject<HTMLElement | null>;
  paperProps?: PaperProps;
  popperProps?: Omit<PopperProps, 'open'>;
  children: React.ReactElement<any>;
  growProps?: GrowProps;
  open: boolean;
  handleClose: (e: MouseEvent | TouchEvent) => any;
}

export const Dropdown = ({
  ref,
  popperProps: _popperProps,
  paperProps: _paperProps,
  growProps: _growProps,
  open,
  handleClose,
  children,
}: DropdownProps) => {
  const { classes, cx, css } = useStyles();
  const popperProps = _popperProps || (stableObject as PopperProps);
  const paperProps = _paperProps || (stableObject as PaperProps);
  const growProps = _growProps || (stableObject as GrowProps);
  const { className, ...otherPaperProps } = paperProps;
  const { className: popperClassName, ...otherPopperProps } = popperProps;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (typeof ref !== 'function' && ref?.current) {
      setAnchorEl(ref.current);
    }
  }, [ref]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      className={cx(css({ zIndex: '12 !important' }), popperClassName)}
      {...otherPopperProps}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'left top',
          }}
          {...growProps}
        >
          <Paper
            className={cx(classes.paper, className)}
            elevation={8}
            {...otherPaperProps}
          >
            <ClickAwayListener onClickAway={handleClose}>
              {children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
  },
}));
