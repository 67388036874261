import { useCallback, useState, useEffect, Fragment } from 'react';
import { string, object } from 'yup';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormikProps, useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  deleteResource,
  fetchResourceById,
  systemSelectors,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';
import {
  FeeType,
  DiscountedRate,
  UnitOfMeasureCategoryType,
  SelectOption,
  ReceiptType,
} from '../../../types';
// import { ErrorIcon } from '../../../assets';

type Mode = 'uom' | 'measurement' | 'package' | 'item' | 'receiptType';
type MeasurementType = 'overSizedWeight' | 'overSizedDimension';

interface Props {
  handleClose: () => any;
  open: boolean;
  customerId: string | number;
  isCreditCardFee?: boolean;
  feeId: number;
  discountId?: number | null;
  setViewVersion: React.Dispatch<React.SetStateAction<number>>;
  feeType?: FeeType;
}

interface State
  extends Omit<
    DiscountedRate,
    'unitOfMeasureCategory' | 'rate' | 'size' | 'quantity' | 'receiptType'
  > {
  unitOfMeasureCategory: UnitOfMeasureCategoryType | '';
  rate: number | '';
  size: number | '';
  quantity: number | '';
  measurementType: MeasurementType;
  receiptType: ReceiptType | '';
}

const receiptTypes: Array<SelectOption<ReceiptType>> = [
  { id: 'Receipt', name: 'Receipt' },
  { id: 'Return', name: 'Return' },
];

const initialState: State = {
  quantity: '',
  feeID: 0,
  unitOfMeasureCategory: '',
  rate: '',
  size: '',
  measurementType: 'overSizedWeight',
  receiptType: '',
};

export function CustomerFeeRateForm({
  open,
  handleClose,
  customerId,
  feeId,
  discountId,
  feeType,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<State>(initialState);
  const [mode, setMode] = useState<Mode>(getInitialMode(feeType));

  useEffect(() => {
    (async function () {
      if (discountId) {
        const { data } = await dispatch(
          fetchResourceById<DiscountedRate>({
            baseUrl: '/customers',
            id: customerId,
            path: `fees/${feeId}/rates/${discountId}`,
          }),
        );
        if (data) {
          if (data.oversizedDimension || data.oversizedWeight) {
            setMode('measurement');
          }
          if (
            feeType === 'ExcessPackageItemFee' ||
            feeType === 'PalletLoadedInboundFee'
          ) {
            if (data.discountedRateType == 'ExcessItems') {
              setMode('item');
            } else if (data.discountedRateType == 'ExcessPackages') {
              setMode('package');
            }
          }
          const formatted = getEditData(data);
          setState((cur) => ({
            ...cur,
            ...formatted,
          }));
        }
      }
    })();
  }, [customerId, discountId, dispatch, feeId, feeType]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = generatePayload(values, mode);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/fees/${feeId}/rates`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [customerId, dispatch, feeId, handleClose, mode, setViewVersion],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/customers',
        id: customerId,
        path: `fees/${feeId}/rates/${discountId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion((cur) => ++cur);
      handleClose();
    }
  }, [customerId, discountId, dispatch, feeId, handleClose, setViewVersion]);

  const formik = useFormik<State>({
    initialValues: {
      ...initialState,
      ...state,
      feeID: feeId,
      measurementType:
        feeType === 'FloorLoadedInboundFee'
          ? 'overSizedDimension'
          : 'overSizedWeight',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          discountId ? 'Edit' : 'Add'
        } additional rate`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!discountId}
      handleDelete={handleDelete}
    >
      <Fragment>
        {!discountId &&
          feeType !== 'FloorLoadedInboundFee' &&
          feeType !== 'PalletLoadedInboundFee' &&
          mode !== 'receiptType' && (
            <ToggleMode mode={mode} setMode={setMode} feeType={feeType} />
          )}
        {mode === 'uom' && <UnitOfMeasureMode formik={formik} />}
        {mode === 'measurement' && (
          <MeasurementMode formik={formik} feeType={feeType} />
        )}
        {(mode === 'item' || mode === 'package') && (
          <QuantityMode formik={formik} />
        )}
        {mode === 'receiptType' && <ReceiptTypeMode formik={formik} />}
        {mode != 'receiptType' &&
          feesWithReceiptTypeFilter.includes(feeType!) && (
            <SelectInput
              name='receiptType'
              formikProps={formik}
              options={receiptTypes}
              label='Receipt type'
              className={classes.input}
            />
          )}
        <TextInput
          name='rate'
          formikProps={formik}
          label='Rate'
          className={classes.input}
          format='currency'
          required
        />
      </Fragment>
    </FormModal>
  );
}

function ToggleMode({
  mode,
  setMode,
  feeType,
}: {
  mode: Mode;
  setMode: any;
  feeType?: FeeType;
}) {
  const { classes, cx } = useStyles();
  const handleChange = useCallback(
    (e) => {
      setMode(e.target.value);
    },
    [setMode],
  );

  return (
    <FormControl
      variant='standard'
      component='fieldset'
      className={cx({ [classes.radios]: mode === 'measurement' })}
    >
      <FormLabel
        component='legend'
        classes={{ root: classes.radioLabel, focused: classes.focused }}
      >
        Mode
      </FormLabel>
      {feeType === 'ExcessPackageItemFee' ? (
        <RadioGroup name='mode' value={mode} onChange={handleChange} row>
          <FormControlLabel
            value={'package' as Mode}
            control={<Radio color='primary' />}
            label='Excess packages'
          />
          <FormControlLabel
            value={'item' as Mode}
            control={<Radio color='primary' />}
            label='Excess items'
          />
        </RadioGroup>
      ) : (
        <RadioGroup name='mode' value={mode} onChange={handleChange} row>
          <FormControlLabel
            value={'uom' as Mode}
            control={<Radio color='primary' />}
            label='Unit of measure'
          />
          <FormControlLabel
            value={'measurement' as Mode}
            control={<Radio color='primary' />}
            label='Measurement'
          />
        </RadioGroup>
      )}
    </FormControl>
  );
}

// function PalletDiscount({ formik }) {
//   const { classes } = useStyles();

//   return (
//     <Fragment>
//       <TextInput
//         name='quantity'
//         formikProps={formik}
//         label='Min Qty'
//         className={classes.input}
//       />
//       <TextInput
//         name='rate'
//         formikProps={formik}
//         label='Rate'
//         className={classes.input}
//         format='currency'
//         required
//       />
//     </Fragment>
//   );
// }

function UnitOfMeasureMode({ formik }: { formik: FormikProps<State> }) {
  const { classes } = useStyles();

  const dimensionTypes = useSelector(systemSelectors.unitOfMeasureTypes);

  return (
    <SelectInput
      name='unitOfMeasureCategory'
      formikProps={formik}
      options={dimensionTypes}
      label='Unit of measure'
      className={classes.input}
    />
  );
}

function ReceiptTypeMode({ formik }: { formik: FormikProps<State> }) {
  const { classes } = useStyles();

  return (
    <SelectInput
      name='receiptType'
      formikProps={formik}
      options={receiptTypes}
      label='Receipt type'
      className={classes.input}
    />
  );
}

function QuantityMode({ formik }: { formik: FormikProps<State> }) {
  const { classes } = useStyles();
  return (
    <TextInput
      name='quantity'
      formikProps={formik}
      label='Quantity'
      className={classes.input}
      required
    />
  );
}

function MeasurementMode({
  formik,
  feeType,
}: {
  formik: FormikProps<State>;
  feeType?: FeeType;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.layout}>
      {/* <div className={classes.warningTextContainer}>
        <ErrorIcon className={classes.errorIcon} />
        <span className={classes.warningText}>
          The rates will be applied in the order they are added
        </span>
      </div> */}
      <div className={classes.smallInputs}>
        {feeType !== 'FloorLoadedInboundFee' && (
          <SelectInput
            name='measurementType'
            formikProps={formik}
            options={[
              { id: 'overSizedWeight', name: 'Oversized weight' },
              { id: 'overSizedDimension', name: 'Oversized dimension' },
            ]}
            label='Oversize category'
            className={classes.input}
            style={{ marginRight: 14 }}
          />
        )}
        {formik.values.measurementType === 'overSizedDimension' ? (
          <TextInput
            name='size'
            label='Size'
            formikProps={formik}
            type='number'
            slotProps={{
              input: {
                endAdornment: <span>in</span>,
              },
            }}
            style={{ maxWidth: 100 }}
          />
        ) : (
          <TextInput
            name='size'
            label='Weight'
            formikProps={formik}
            type='number'
            slotProps={{
              input: {
                endAdornment: <span>lb</span>,
              },
            }}
            style={{ maxWidth: 100 }}
          />
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    marginTop: '-12px',
    marginBottom: 8,
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 18,
    color: '#FFBD00',
    background: '#FFFFFF',
    marginRight: 6,
  },
  warningText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
  radios: {
    marginBottom: 24,
  },
  radioLabel: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    '&.Mui-focused': {
      color: '#000000',
    },
  },
  focused: {},
  layout: {
    display: 'flex',
    flexDirection: 'column',
  },
  smallInputs: {
    display: 'flex',
  },
}));

const feesWithReceiptTypeFilter: Array<FeeType> = [
  'PalletLoadedInboundFee',
  'SmallParcelInboundFee',
  'SortingItemsFee',
  'SerialNumberHandlingFee',
  'ExpirationDateTrackingFee',
  'LotNumberTrackingFee',
];

function getInitialMode(feeType?: FeeType): Mode {
  // feeType === 'ExcessPackageItemFee'
  // ? 'package'
  // : feeType === 'FloorLoadedInboundFee'
  //   ? 'measurement'
  //   : 'uom',
  if (
    feesWithReceiptTypeFilter.includes(feeType!) &&
    feeType !== 'PalletLoadedInboundFee'
  ) {
    return 'receiptType';
  }
  switch (feeType) {
    case 'ExcessPackageItemFee':
      return 'package';
    case 'FloorLoadedInboundFee':
      return 'measurement';
    case 'PalletLoadedInboundFee':
      return 'item';
    default:
      return 'uom';
  }
}

function getEditData(data) {
  const {
    dimensionCategoryDisplay,
    dimensionDisplay,
    oversizedDimension,
    oversizedWeight,
    ...rest
  } = data;
  return {
    ...convertNullFieldsToEmptyString(rest),
    size: oversizedDimension ?? oversizedWeight ?? '',
    measurementType: oversizedWeight ? 'overSizedWeight' : 'overSizedDimension',
  };
}

function generatePayload(values: State, mode: Mode): DiscountedRate {
  const {
    unitOfMeasureCategory,
    size,
    measurementType,
    rate,
    quantity,
    receiptType,
    ...rest
  } = values;
  let rv: DiscountedRate = {
    ...rest,
    rate: rate !== '' ? rate : undefined,
    id: values.id || null,
    receiptType: receiptType || undefined,
  };
  if (mode === 'item') {
    rv.discountedRateType = 'ExcessItems';
    rv.quantity = quantity || undefined;
  } else if (mode === 'package') {
    rv.discountedRateType = 'ExcessPackages';
    rv.quantity = quantity || undefined;
  } else if (mode === 'measurement') {
    rv = {
      ...rv,
      unitOfMeasureCategory: 'Master',
    };
    if (measurementType === 'overSizedDimension') {
      rv.oversizedDimension = size || undefined;
    } else {
      rv.oversizedWeight = size || undefined;
    }
  } else {
    rv.unitOfMeasureCategory = unitOfMeasureCategory || undefined;
  }
  return rv;
}

const schema = object().shape({
  rate: string().required('Required'),
});
