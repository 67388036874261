import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
  useSelector,
  systemSelectors,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import {
  WarehouseFeeAPI,
  WarehouseStorageCategoryType,
  FeeType,
  SelectOption,
} from '../../../types';
import { Grid2 } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  feeId?: number;
}

interface State
  extends Omit<
    WarehouseFeeAPI,
    'warehouseID' | 'feeType' | 'locationCategoryType'
  > {
  feeType: FeeType | '';
  locationCategoryType: WarehouseStorageCategoryType | '';
}

const validFeeTypes: Array<FeeType> = ['WarehouseStorageFee'];
const validFeesCount = validFeeTypes.length;

const initialState: State = {
  feeName: '',
  feeType: validFeesCount === 1 ? validFeeTypes[0] : '',
  locationCategoryType: '',
  lengthInches: '',
  widthInches: '',
  heightInches: '',
  amount: '',
};

export function WarehouseFeeForm({
  open,
  handleClose,
  warehouseId,
  feeId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const allFeeTypes = useSelector(systemSelectors.feeTypes);
  const categoryTypes = useSelector(
    systemSelectors.warehouseStorageCategoryTypes,
  );

  const [submitting, setSubmitting] = useState(false);
  const [feeTypes, setFeeTypes] = useState<Array<SelectOption<FeeType>>>([]);
  const [state, setState] = useState<State>(initialState);

  const isEdit = !!feeId;

  useEffect(() => {
    setFeeTypes(allFeeTypes.filter((f) => validFeeTypes.includes(f.id)));
  }, [allFeeTypes]);

  useEffect(() => {
    (async function () {
      if (feeId) {
        const { data } = await dispatch(
          fetchResourceById<WarehouseFeeAPI>({
            baseUrl: '/warehouses',
            id: warehouseId,
            path: `fees/${feeId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      }
    })();
  }, [warehouseId, dispatch, feeId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload: WarehouseFeeAPI = {
        ...values,
        id: feeId || undefined,
        warehouseID: warehouseId,
        feeType: values.feeType || undefined,
        locationCategoryType: values.locationCategoryType || undefined,
      };
      const { error } = await dispatch(
        addResource<WarehouseFeeAPI, WarehouseFeeAPI>({
          baseUrl: `/warehouses/${warehouseId}/fee`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [feeId, dispatch, handleClose, refresh, warehouseId],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `fees/${feeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, feeId]);

  const formik = useFormik<State>({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          feeId ? 'Edit' : 'Add'
        } warehouse fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={isEdit}
      handleDelete={handleDelete}
    >
      <Grid2 container spacing={5} rowSpacing={2} marginBottom={0}>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='feeName'
            formikProps={formik}
            label='Name'
            required
            className={classes.input}
            disabled={isEdit}
          />
        </Grid2>
        {validFeesCount > 1 && (
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='feeType'
              formikProps={formik}
              label='Fee type'
              options={feeTypes}
              disabled={!!feeId}
              required
              className={classes.input}
            />
          </Grid2>
        )}
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            name='locationCategoryType'
            formikProps={formik}
            label='Location category'
            options={categoryTypes}
            className={classes.input}
            required
            disabled={isEdit}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='lengthInches'
            formikProps={formik}
            label='Length (inches)'
            className={classes.input}
            required
            disabled={isEdit}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='widthInches'
            formikProps={formik}
            label='Width (inches)'
            className={classes.input}
            required
            disabled={isEdit}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='heightInches'
            formikProps={formik}
            label='Height (inches)'
            className={classes.input}
            required
            disabled={isEdit}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='amount'
            formikProps={formik}
            label='Amount'
            className={classes.input}
            required
            format='currency'
          />
        </Grid2>
      </Grid2>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { WarehouseFeeForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));

const schema = object().shape({
  feeName: string().required('Required'),
  feeType: string().required('Required'),
  locationCategoryType: string().required('Required'),
  lengthInches: string().required('Required'),
  widthInches: string().required('Required'),
  heightInches: string().required('Required'),
  amount: string().required('Required'),
});
