import { useCallback, useState, useEffect } from 'react';
import _get from 'lodash.get';
import { makeStyles } from 'tss-react/mui';
import { Chip, ChipProps, Grid2Props, Grid2 as Grid } from '@mui/material';
import { stableObject } from '../../../lib';
import { InputFormikProps } from '../../../types';

interface ChipInputProps {
  chipProps?: Omit<ChipProps, 'name' | 'value'>;
  label: string;
  value: string;
}

interface ChipsInputProps {
  name: string;
  value?: string;
  label?: string;
  className?: string;
  gridProps?: Omit<Grid2Props, 'direction' | 'spacing'>;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  formikProps?: InputFormikProps;
  handleChange?: (e: React.ChangeEvent<any>) => any;
  useCustomValue?: boolean;
  required?: boolean;
  disabled?: boolean;
  validationKey?: string | Array<string>;
  chips: Array<ChipInputProps>;
}

export function ChipsInput({
  name,
  value,
  label,
  className,
  handleChange,
  chips,
  formikProps,
  useCustomValue,
  required,
  disabled,
  validationKey,
  direction = 'row',
  gridProps: _gridProps,
}: ChipsInputProps) {
  const { classes, cx } = useStyles();
  const gridProps = _gridProps || stableObject;

  const {
    handleChange: formikHandleChange,
    values,
    touched,
    errors,
  } = formikProps || {};

  const selectedValue =
    (formikProps !== undefined && !useCustomValue ? values[name] : value) ?? '';

  const [selectedOption, setSelectedOption] = useState(selectedValue);

  useEffect(() => {
    // If the value is manually changed not via the onChange
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  const onChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      const clickValue = e.currentTarget.getAttribute('data-value');
      setSelectedOption(clickValue);
      const event: React.ChangeEvent<any> = {
        ...e,
        target: {
          ...e.target,
          value: clickValue,
          name,
        },
      };
      formikHandleChange && !useCustomValue && formikHandleChange(event);
      handleChange && handleChange(event);
    },
    [formikHandleChange, handleChange, name, useCustomValue],
  );

  const isTouched = !!(_get(touched, name) || _get(touched, validationKey));
  const errorText = _get(errors, validationKey ?? name);

  return (
    <div className={cx(classes.layout, className)}>
      {!!label && (
        <div
          className={cx(classes.label, {
            [classes.error]: isTouched && !!errorText,
          })}
        >
          {label}
          {required && <span className={classes.required}> *</span>}
        </div>
      )}
      <Grid container direction={direction} spacing={2} {...gridProps}>
        {chips.map((c) => {
          const { label: chipLabel, value: chipValue, chipProps } = c;
          const {
            className,
            disabled: chipDisabled,
            ...otherChipProps
          } = chipProps || {};

          const isDisabled = chipDisabled || disabled;

          return (
            <Grid
              key={chipValue}
              size={{
                sm: 'auto',
              }}
            >
              <Chip
                variant='outlined'
                classes={{
                  root: cx(
                    classes.root,
                    {
                      [classes.active]: chipValue === selectedOption,
                    },
                    className,
                  ),
                  disabled: cx(classes.borderDisabled, {
                    [classes.activeDisabled]: chipValue === selectedOption,
                  }),
                }}
                {...otherChipProps}
                clickable
                onClick={onChange}
                data-value={chipValue}
                label={chipLabel}
                disabled={isDisabled}
              />
            </Grid>
          );
        })}
      </Grid>
      {isTouched && !!errorText && (
        <span className={cx(classes.error, classes.smallErrorText)}>
          {errorText}
        </span>
      )}
    </div>
  );
}

const useStyles = makeStyles({ name: { ChipsInput } })((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    height: 90,
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: 16,
    whiteSpace: 'pre-wrap',
  },
  error: {
    color: theme.palette.error.main,
  },
  smallErrorText: {
    fontSize: '0.75rem',
    marginTop: 4,
  },
  root: {
    fontSize: 13,
    border: '1px solid #D5D8EC',
  },
  borderDisabled: {
    border: '1px solid rgba(213, 216, 236, 0.38)',
  },
  active: {
    border: '1px solid #00C4F8',
    background: 'rgba(0,196,248,0.08)',
    color: '#00C4F8',
    fontSize: 13,
    letterSpacing: 0,
  },
  activeDisabled: {
    background: 'rgba(0,196,248,0.28)',
    color: '#000000',
  },
  required: {
    color: '#ff4f5b',
    marginLeft: 4,
  },
}));
