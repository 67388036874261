import { Fragment, useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { getFirstObjectValueFromKeysArray, isObject } from '../../lib';
import { useStyles } from './selectScanner.styles';
import { SyntheticEventType } from '../../types';

interface Props {
  ref?: React.RefObject<HTMLDivElement>;
  scanValidatorFunc: (value: {
    value: string | number;
  }) =>
    | { data?: any; error?: string }
    | Promise<{ data?: any; error?: string }>;
  onScan: (
    data: any,
  ) => { error?: string } | Promise<{ error?: string }> | void | Promise<void>;
  wrapInCard?: boolean;
  options: any[];
  optionsLabelProperties?: string[];
  optionsValueProperties?: string[];
  label?: string;
  name?: string;
  scanTextOverride?: string;
  groupBy?: string;
  disabled?: boolean;
  className?: string;
  inputRef?: React.Ref<any>;
  onInputChange?: (event: SyntheticEventType) => void;
  clearInputOnSelect?: boolean;
  autoFocus?: boolean;
  validateOnBlur?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string | null;
}

export const AutoCompleteScanner = ({
  ref,
  scanValidatorFunc,
  onScan,
  wrapInCard = true,
  options,

  // type should be array to allow multiple
  optionsLabelProperties,

  // type should be array to allow multiple
  optionsValueProperties,

  label = 'Scan or enter an item',
  name,
  scanTextOverride,
  groupBy,
  disabled,
  className,
  inputRef,
  onInputChange,
  clearInputOnSelect = true,
  autoFocus = true,
  validateOnBlur = false,
  required = false,
  error: _error,
  helperText,
}: Props) => {
  const { classes, cx } = useStyles();
  const [scanText, setScanText] = useState(''); // the value of the input
  const [autocompleteValue, setAutocompleteValue] = useState(''); // the value of the autocomplete component
  const [error, setError] = useState<string | null>(null);

  const handleSelect = async (e, newInputValue, reason) => {
    const value = isObject(newInputValue)
      ? getFirstObjectValueFromKeysArray({
          obj: newInputValue,
          keysArr: optionsValueProperties,
        }) || ''
      : newInputValue;
    // setAutocompleteValue(value);

    if (!value) return setError('Please scan or enter an item');
    const { data, error } = await scanValidatorFunc({ value });

    if (error) {
      setScanText('');
      return setError(error);
    }
    if (onScan) {
      const { error: scanError } = (await onScan(data)) || {};
      if (scanError) {
        setScanText('');
        return setError(scanError);
      }
      setError(null);
    }
    if (clearInputOnSelect) {
      setScanText('');
    }
  };

  const handleBlur = async (e) => {
    if (required && !scanText) {
      setError('This field is required');
    } else {
      await handleSelect(e, scanText, null);
    }
  };

  useEffect(() => {
    if (!scanText && autocompleteValue) {
      setAutocompleteValue('');
    }
  }, [autocompleteValue, scanText]);

  if (!options) return null;

  return (
    <Fragment>
      <div
        className={cx(
          classes.content,
          { [classes.card]: wrapInCard },
          className,
        )}
      >
        <Autocomplete
          options={options}
          getOptionLabel={(option) => {
            return isObject(option)
              ? getFirstObjectValueFromKeysArray({
                  obj: option,
                  keysArr: optionsLabelProperties,
                })
              : (option?.toString() ?? '');
          }}
          isOptionEqualToValue={(option, value) => {
            const optionValue = getFirstObjectValueFromKeysArray({
              obj: option,
              keysArr: optionsValueProperties,
            });
            const valueValue = getFirstObjectValueFromKeysArray({
              obj: value,
              keysArr: optionsValueProperties,
            });
            return optionValue === valueValue;
          }}
          groupBy={groupBy ? (option) => option[groupBy] : undefined}
          inputValue={scanTextOverride ?? scanText} // the text input
          onInputChange={(event, newInputValue) => {
            setScanText(newInputValue);
            onInputChange ? onInputChange(event) : undefined;
          }}
          onChange={handleSelect}
          onBlur={validateOnBlur ? handleBlur : undefined}
          className={classes.input}
          openOnFocus={false}
          value={autocompleteValue}
          freeSolo
          autoComplete
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              variant='standard'
              {...params}
              name={name}
              label={label}
              error={_error || Boolean(error)}
              helperText={error ? helperText : error || helperText}
              ref={ref}
              inputRef={inputRef}
              autoFocus={autoFocus}
              required={required}
              slotProps={{ inputLabel: { className: classes.label } }}
            />
          )}
        />
      </div>
    </Fragment>
  );
};
