import { addHours, isBefore, parseISO } from 'date-fns';
import { authGet, authPost, authPut } from '../../lib';
import {
  showError,
  showNotification,
  getCustomers,
  setUILoading,
} from '../features';
import { addResource } from '../asyncActions';
import type { AppThunk, NormalizeReturn } from '../types';
import type { IdNamePair } from '../../types/globalTypes';
import {
  BasicCustomerSettingAPI,
  CustomerContact,
  CustomerItemAPI,
  InvoiceFrequencyJobRequest,
  ItemUnitOfMeasureAPI,
  VerifyUomDimensionsRequest,
} from '../../types';

export function getCustomerById(
  customerId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`customers/${customerId}`);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getCustomerSettings(
  customerId: number | string,
): AppThunk<Promise<NormalizeReturn<BasicCustomerSettingAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<BasicCustomerSettingAPI>(
      `customers/${customerId}/basic-settings`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getCustomerItemSettings(
  customerId: number | string,
  customerItemId: number | string,
): AppThunk<Promise<NormalizeReturn<CustomerItemAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<CustomerItemAPI>(
      `customers/${customerId}/item-settings/${customerItemId}`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function verifyItemUom({
  customerId,
  customerItemId,
  uomId,
  shouldSetUILoading = true,
}: {
  customerId: number | string;
  customerItemId: number | string;
  uomId: number | string;
  shouldSetUILoading?: boolean;
}): AppThunk<Promise<NormalizeReturn<Date>>> {
  return async (dispatch) => {
    shouldSetUILoading && dispatch(setUILoading(true));
    const { data, error } = await authPut<Date>(
      `customers/${customerId}/item-settings/${customerItemId}/unit-of-measure/${uomId}/verify`,
      {},
    );
    shouldSetUILoading && dispatch(setUILoading(false));
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function verifyItemUomDimensions({
  customerId,
  customerItemId,
  uomId,
  payload,
  shouldSetUILoading = true,
}: {
  customerId: number | string;
  customerItemId: number | string;
  uomId: number | string;
  payload: VerifyUomDimensionsRequest;
  shouldSetUILoading?: boolean;
}): AppThunk<Promise<NormalizeReturn<ItemUnitOfMeasureAPI>>> {
  return async (dispatch) => {
    shouldSetUILoading && dispatch(setUILoading(true));
    const { data, error } = await authPut<ItemUnitOfMeasureAPI>(
      `customers/${customerId}/item-settings/${customerItemId}/unit-of-measure/${uomId}/verify-dimensions`,
      payload,
    );
    shouldSetUILoading && dispatch(setUILoading(false));
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function setAsPreferredCarrier(
  customerId: number | string,
  carrierId: number | string,
  payload: object,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authPost(
      `customers/${customerId}/carrier-settings/${carrierId}/set-default`,
      payload,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(showNotification({ message: 'Changes saved' }));
    return { data };
  };
}

export function getCustomerContacts(
  customerId: number | string,
  includeUsers?: boolean,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet([
      `customers/${customerId}/contacts/emails`,
      {
        includeUsers,
      },
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getCustomerContactById(
  customerId: number | string,
  contactId: number | string,
): AppThunk<Promise<NormalizeReturn<CustomerContact>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<CustomerContact>(
      `customers/${customerId}/contacts/${contactId}`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getCustomerPrimaryContact(
  customerId: number | string,
): AppThunk<Promise<NormalizeReturn<CustomerContact>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<CustomerContact>(
      `customers/${customerId}/contacts/primary`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getCustomerBasicList(
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch, getState) => {
    const { customers: { basicCustomers, lastFetchedCustomersOn } = {} } =
      getState();
    if (
      !ignoreCache &&
      basicCustomers?.length &&
      !!lastFetchedCustomersOn &&
      isBefore(new Date(), addHours(parseISO(lastFetchedCustomersOn), 1))
    ) {
      return {
        data: basicCustomers,
      };
    }
    const { data, error } =
      await authGet<Array<IdNamePair>>('/customers/basic');
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(getCustomers(data));
    return { data };
  };
}

export function getQbCustomerDetails(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`/customers/${id}/qb-customer`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function addQbCustomer(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/customers/${id}/create-qb-customer`,
        payload: {},
        message: 'Changes saved',
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getCustomerPaymentMethods(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`/customers/${id}/payment-methods`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function addCustomerPaymentMethod(
  id: number | string,
  payload: object,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/customers/${id}/add-payment-method`,
        payload,
        message: 'Changes saved',
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getCustomerCCFees(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`/customers/${id}/credit-card-fees`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getInvoiceDetails(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`/accounting/invoice/${id}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getInvoiceCharges(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`/accounting/invoice/${id}/charges`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getInvoiceFSDocuments(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(
      `/accounting/invoice/${id}/fulfilled-service-documents`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function triggerCreateInvoice(
  payload: InvoiceFrequencyJobRequest,
  message?: string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/accounting/invoice/create/run-job`,
        payload,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
        message,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function sendInvoiceEmail(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/accounting/invoice/${id}/send-email`,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
        message: 'Email sent to customer',
        payload: {},
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function voidInvoice(
  id: number | string,
  payload: Record<string, any>,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/accounting/invoice/${id}/void`,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
        message:
          'Successfully voided the invoice and the invoice will update when it is done processing.',
        payload,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getIntegrationToken(
  customerId: number | string,
  regenerateToken: boolean,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authPost(
      [`customers/${customerId}/integration-user`, { regenerateToken }],
      {},
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}
