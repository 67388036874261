import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  getInventoryItemHolds,
  placeInventoryOnHold,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { Button, Grid2 as Grid } from '@mui/material';
import { InventoryItemHoldsResponse } from '../../../types';

interface Props {
  customerId: number;
  customerItemId: number;
  open: boolean;
  handleClose: () => any;
  locationName: string;
  moveableUnitId: number | string | null;
  primaryQuantityOnHold: number | null;
  receiptIDs?: number[] | string[];
  sku: string;
  totalPrimaryAvailable: number | null;
  warehouseLocationId: number;
}

interface State {
  quantity: number | null;
  reason: string;
}

const initialState: State = {
  quantity: null,
  reason: '',
};

export function InventoryHoldReleaseForm({
  customerId,
  customerItemId,
  open,
  handleClose,
  locationName,
  moveableUnitId,
  primaryQuantityOnHold,
  receiptIDs,
  sku,
  totalPrimaryAvailable,
  warehouseLocationId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [holds, setHolds] = useState<InventoryItemHoldsResponse[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedHoldId, setSelectedHoldId] = useState<number | null>(null);

  const parsedMoveableUnitId =
    moveableUnitId !== null ? Number(moveableUnitId) : null;

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getInventoryItemHolds({
          customerItemID: customerItemId,
          moveableUnitID: parsedMoveableUnitId,
          receiptIDs,
        }),
      );
      if (data) {
        setHolds(data);
      }
    })();
  }, [customerItemId, dispatch, parsedMoveableUnitId, receiptIDs]);

  const handleRelease = useCallback(
    async (id: number) => {
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: '/inventory/release-on-hold',
          payload: {
            locationItemHoldIDs: [id],
          },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
        setShowConfirmation(false);
      }
    },
    [dispatch, handleClose, refresh],
  );

  const handleConfirmRelease = useCallback(() => {
    if (selectedHoldId) {
      handleRelease(selectedHoldId);
    }
  }, [handleRelease, selectedHoldId]);

  const handleOpenConfirmation = useCallback((id: number) => {
    setSelectedHoldId(id);
    setShowConfirmation(true);
  }, []);

  const handleCloseConfirmation = useCallback(() => {
    setShowConfirmation(false);
    setSelectedHoldId(null);
  }, []);

  const handleSubmit = useCallback(async (values: State) => {
    setSubmitting(true);
    const { error } = await dispatch(
      placeInventoryOnHold({
        locationItems: [
          {
            ...values,
            customerID: customerId,
            customerItemID: customerItemId,
            moveableUnitID: parsedMoveableUnitId,
            scannedItem: sku,
            warehouseLocationID: warehouseLocationId,
          },
        ],
      }),
    );
    setSubmitting(false);
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      ...initialState,
      quantity: totalPrimaryAvailable,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <div className={classes.titleContainer}>
          <span className={classes.title}>Hold or release</span>
          <span className={classes.subTitle}>
            You can place inventory stock on hold and manually release it at any
            point.
          </span>
        </div>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <span className={classes.sku}>{sku}</span>
            <span className={classes.skuLocation}>{`in ${locationName}`}</span>
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <TextInput
              name='quantity'
              formikProps={formik}
              label='Primary qty'
              className={classes.input}
              type='number'
              slotProps={{
                input: {
                  inputProps: {
                    max: totalPrimaryAvailable,
                    min: 0,
                  },
                },
              }}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <TextInput
              name='reason'
              formikProps={formik}
              label='Reason'
              className={classes.input}
            />
          </Grid>
        </Grid>
        {primaryQuantityOnHold! > 0 && (
          <div className={classes.holdsContainer}>
            <div className={classes.holdTitle}>Holds</div>
            {holds.map(({ id, note, quantity }) => (
              <div key={id} className={classes.holdItem}>
                <div className={classes.holdItemInfo}>
                  <span>Primary</span>
                  <span style={{ minWidth: 15 }}>{quantity} </span>
                  <span className={classes.holdReason}>{note}</span>
                </div>
                {id && (
                  <Button
                    variant='text'
                    onClick={() => handleOpenConfirmation(id)}
                    className={classes.releaseButton}
                  >
                    Release
                  </Button>
                )}
              </div>
            ))}
          </div>
        )}
        {showConfirmation && (
          <FormModal
            open={showConfirmation}
            handleClose={handleCloseConfirmation}
            title={<span className={classes.title}>Confirm Release</span>}
            callback={handleConfirmRelease}
            btnText='Confirm'
            paperProps={{ style: { width: 510 } }}
            submitting={submitting}
          >
            <div>Are you sure you want to release this hold?</div>
          </FormModal>
        )}
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { InventoryHoldReleaseForm } })(
  (theme) => ({
    layout: {
      padding: '0 16px 12px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px 0px',
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    subTitle: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      lineHeight: '16px',
      letterSpacing: 0,
    },
    input: {
      marginBottom: 16,
      width: '100%',
    },
    sku: {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '16px',
      marginRight: 24,
    },
    skuLocation: {
      color: '#888EA7',
      fontFamily: 'Lato',
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    },
    holdsContainer: {
      marginTop: 24,
    },
    holdTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: '#333',
      marginBottom: 8,
    },
    holdItem: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 8,
      padding: '4px 8px 5px',
      borderRadius: 2,
      backgroundColor: '#FF4F5B1F',
      color: '#FF4F5B',
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '13px',
    },
    holdItemInfo: {
      display: 'flex',
      gap: 24,
    },
    holdReason: {
      fontWeight: 500,
      fontStyle: 'italic',
    },
    releaseButton: {
      fontSize: 12,
      color: '#FF4F5B',
      cursor: 'pointer',
      textDecoration: 'underline',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '13px',
      padding: 'unset',
      textTransform: 'capitalize',
    },
  }),
);
